import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarIntelledtualProperty = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc30c9d0dd86508e901435?date=jit_15";
  const title = "Startup IP Secrets They Don't Want You To Know in 2025";
  const canonical = "/webinar-intellectual-property-management-for-startups";
  const introAuthor = "Bao Tran is the founder of PatentPC, specializing in startup intellectual property."
  const lessons = [
    "The importance of IP in startup ecosystems.",
    "Cost-effective IP strategies for early-stage companies.",
    "Protecting your startup’s innovations and brand.",
    "Navigating IP agreements and licensing."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarIntelledtualProperty;